<template>
  <component :is="page" v-if="response" :links="response._links"></component>
</template>

<script lang="ts" setup>
import type { PageResponse } from "~/types/response.ts";

const params = useRoute().params;

const mapping = {
  reederei: resolveComponent("ViewReederei"),
  hafen: resolveComponent("ViewHafen"),
  schiff: resolveComponent("ViewSchiff"),
  zielgebiet: resolveComponent("ViewZielgebiet"),
  reise: resolveComponent("ViewReise"),
  "/": resolveComponent("View"),
};

const route = Array.isArray(params.slug) ? params.slug.join("/") : params.slug;
const page = shallowRef(null);

if (route.startsWith("media")) {
  window.location.href = route;
  window.location.reload();
}

const { data: response } = await useGo7seasApi<PageResponse>(
  apiUrl(`/catalog/page/${route}`)
);

page.value = mapping[response.value.page_type];

if (!response.value._links) {
  throw createError({
    statusCode: 500,
    fatal: true,
  });
}
</script>
