<template>
  <div class="my-8 flex flex-row border text-g7-blue lg:border-2">
    <div class="sm:w-32 sm:border-r md:w-48 lg:w-1/6 lg:border-r-2">
      <CloudinaryImg
        :key="item.image.public_id"
        :public-id="item.image.public_id"
        :steps="[200, 400]"
        class="h-32 object-cover md:h-48"
      />
    </div>

    <div class="flex w-1/4 flex-col p-2 md:p-4">
      <Headline class="pb-2 font-black md:pb-0" :title="item.title" />
      <Headline
        class="sm:line-clamp-1 lg:line-clamp-none"
        size="XL"
        :title="item.subtitle"
      />
      <div v-if="item.color_code" class="flex grow items-end">
        <G7Button
          color="orange"
          :label="
            open ? item.show_less_button?.label : item.show_more_button?.label
          "
          @click="open = !open"
        />
      </div>
    </div>

    <div class="w-1/3 grow px-1 pb-2 sm:p-2 md:p-4">
      <ul>
        <li
          v-for="(description, index) in item.description"
          :key="index"
          class="flex items-center"
          :class="{ hidden: !open && index >= limit }"
        >
          <div class="flex items-start">
            <div class="flex items-center">
              <IconsUp class="size-6 rotate-90 fill-g7-blue" />
            </div>
            <span class="text-xs sm:text-sm md:ml-1 md:text-base">
              {{ description }}</span
            >
          </div>
        </li>
      </ul>
    </div>

    <div v-if="item.color_code" class="flex items-start justify-end p-2 md:p-4">
      <CloudinaryImg :public-id="item.color_code" class="size-9" />
    </div>

    <div
      v-if="item.color_code == undefined"
      class="hidden items-end justify-end p-2 sm:flex md:p-4"
    >
      <G7Button
        color="orange"
        :label="
          open ? item.show_less_button?.label : item.show_more_button?.label
        "
        @click="open = !open"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CabinType } from "~~/types/ships";

const props = defineProps<{
  item: CabinType;
}>();

const open = ref(false);
const limit = computed(() => {
  return open.value ? props.item.description.length : 5;
});
</script>
